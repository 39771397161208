<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 text-uppercase">
        <v-icon class="cursor--pointer" @click="backleads">mdi-reply</v-icon>

        {{ "Leads" }}
      </div>
    </div>
    <v-card color="white" elevation="1">
      <v-card-title class="primary white--text" primary-title>
        Import Lead
      </v-card-title>
      <template>
        <v-stepper v-model="e6" vertical>
          <v-stepper-step :complete="e6 > 1" step="1">
            Upload File
            <small>Files format: xls, csv</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-file-input
              v-model="file"
              accept=".xls,.csv"
              show-size
              truncate-length="15"
              label="Select File"
            ></v-file-input>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || file == null"
              @click="firstStep"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2">
            Headers Preview
            <small>Select files to match</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <template v-if="headersData.length != 0">
              <v-row class="pa-1">
                <v-col cols="12" sm="6">
                  <v-select
                    :items="headersData"
                    v-model="headers.name"
                    label="Name *"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.last_name"
                    label="Last name"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.phone"
                    label="Phone *"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.email"
                    label="Email *"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.created_time"
                    label="Created time"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.campaing"
                    label="Campaing"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.howKnow"
                    label="How know"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.status"
                    label="Status"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.weight"
                    label="Weight"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.height"
                    label="Height"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.birthDate"
                    label="Birth date"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.procedureInterest"
                    label="Procedure of interest"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.zipCode"
                    label="Zip code"
                    outlined
                    clearable
                    dense
                  ></v-select>
                </v-col>
                <template v-if="firstData != null">
                  <v-col class="d-flex flex-column" cols="12" sm="6">
                    <v-text-field
                      label="Name"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.name)"
                    ></v-text-field>
                    <v-text-field
                      label="Last Name"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.last_name)"
                    ></v-text-field>
                    <v-text-field
                      label="Phone"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.phone)"
                    ></v-text-field>
                    <v-text-field
                      label="Email"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.email)"
                    ></v-text-field>
                    <v-text-field
                      label="Created time"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.created_time)"
                    ></v-text-field>
                    <v-text-field
                      label="Campaing"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.campaing)"
                    ></v-text-field>
                    <v-text-field
                      label="How know"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.howKnow)"
                    ></v-text-field>
                    <v-text-field
                      label="Status"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.status)"
                    ></v-text-field>
                    <v-text-field
                      label="Weight"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.weight)"
                    ></v-text-field>
                    <v-text-field
                      label="Height"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.height)"
                    ></v-text-field>
                    <v-text-field
                      label="Birth date"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.birthDate)"
                    ></v-text-field>
                    <v-text-field
                      label="Procedure of interest"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.procedureInterest)"
                    ></v-text-field>
                    <v-text-field
                      label="Zip code"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.zipCode)"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
              <v-alert outlined type="error" :value="!validMatch">
                Fields with (*) are required
              </v-alert>
            </template>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || !validMatch"
              @click="secondStep"
            >
              Match
            </v-btn>
            <v-btn text @click="cancelStep"> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3">
            Data Preview
            <small>Examples: first and last element </small>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-row>
              <v-col cols="12" sm="6">
                <span class="text-decoration-underline font-weight-bold"
                  >First</span
                >
                <template v-if="firstResult">
                  <div class="d-flex flex-column">
                    <div
                      class="my-1"
                      v-for="(value, key, index) in firstResult"
                      :key="index"
                    >
                      <strong>{{ key }}:</strong> {{ value }}
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="text-decoration-underline font-weight-bold"
                  >Last</span
                >
                <template v-if="lastResult">
                  <div class="d-flex flex-column">
                    <div
                      class="my-1"
                      v-for="(value, key, index) in lastResult"
                      :key="index"
                    >
                      <strong>{{ key }}:</strong> {{ value }}
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="lastStep"
            >
              Confirm
            </v-btn>
            <v-btn text @click="cancelStep"> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-step step="4" :complete="e6 == 4">
            Import successful
          </v-stepper-step>
          <v-stepper-content step="4">
            <div class="warning ma-1">
              <strong>Existed:</strong>
              <span>{{ existsLeads.length }}</span>
            </div>
            <div class="ma-1 success">
              <strong>Inserted:</strong>
              <span>{{ insertedLeads.length }}</span>
            </div>

            <v-btn color="primary" @click="resetAll"
              ><v-icon class="mr-1">mdi-cloud-upload</v-icon> import another
            </v-btn>
            <v-btn class="mx-2" color="warning" @click="backleads">
              <v-icon>mdi-exit-to-app</v-icon> Exit
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </template>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import cleanObject from "@/utils/cleanObject";
import { notifyError } from "@/components/Notification";
export default {
  name: "import-lead",
  data() {
    return {
      e6: 1,
      file: null,
      loading: false,

      headers: {
        name: null,
        last_name: null,
        phone: null,
        email: null,
        created_time: null,
        campaing: null,
        howKnow: null,
        status: null,
        weight: null,
        height: null,
        birthDate: null,
        procedureInterest: null,
        zipCode: null,
      },
      firstData: null,
      firstResult: null,
      configResult: null,
      lastResult: null,
      existsLeads: [],
      insertedLeads: [],
      headersData: [],
    };
  },
  watch: {
    e6(val) {
      //
    },
  },
  computed: {
    validMatch() {
      if (
        this.headers.name != null &&
        this.headers.phone != null &&
        this.headers.email != null
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    backleads() {
      this.$router.push("/leads_center");
    },
    getData(value) {
      return this.firstData[value];
    },
    firstStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      getAPI
        .post("/social/decodeFilePreview", formDatafile)
        .then((res) => {
          this.loading = false;
          this.headersData = res.data.headers;
          this.firstData = res.data.first;
          this.e6 = 2;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    secondStep() {
      this.loading = true;
      let body = Object.assign({}, this.headers);
      body = cleanObject(body);
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      Object.entries(body).forEach(([key, value]) => {
        formDatafile.append(key, value);
      });
      getAPI
        .post("/social/matcheLeadsPreview", formDatafile)
        .then((res) => {
          this.loading = false;
          this.firstResult = res.data.first;
          this.lastResult = res.data.last;
          this.configResult = res.data.config;
          this.e6 = 3;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    lastStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      Object.entries(this.configResult).forEach(([key, value]) => {
        formDatafile.append(key, value);
      });
      getAPI
        .post("/social/insertLeadsFromfile", formDatafile)
        .then((res) => {
          this.loading = false;
          this.existsLeads = res.data.existing;
          this.insertedLeads = res.data.inserted;
          this.e6 = 4;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    resetStepHeaders() {
      this.firstData = null;
      this.headersData = [];
      this.headers = {
        name: null,
        last_name: null,
        phone: null,
        email: null,
        created_time: null,
        campaing: null,
        howKnow: null,
        status: null,
        weight: null,
        height: null,
        birthDate: null,
        procedureInterest: null,
        zipCode: null,
      };
    },

    resetAll() {
      this.e6 = 1;
      this.file = null;
      this.loading = false;

      this.headers = {
        name: null,
        last_name: null,
        phone: null,
        email: null,
        created_time: null,
        campaing: null,
        howKnow: null,
        status: null,
        weight: null,
        height: null,
        birthDate: null,
        procedureInterest: null,
        zipCode: null,
      };
      this.firstData = null;
      this.firstResult = null;
      this.configResult = null;
      this.lastResult = null;
      this.existsLeads = [];
      this.insertedLeads = [];
      this.headersData = [];
    },
    cancelStep() {
      if (this.e6 == 2) {
        this.resetStepHeaders();
      } else if (this.e6 == 3) {
        this.firstResult = null;
        this.lastResult = null;
        this.configResult = null;
      }
      this.e6 = this.e6 - 1;
    },
  },
};
</script>
<style lang=""></style>
